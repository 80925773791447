/* eslint-disable no-extend-native */
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Autocomplete,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MafiDragAndDrop from "../../../@mafi/MafiDragAndDrop";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL;
let dataUsers = [];
let usersRows = [];
let customerUsers = [];
let staffUsers = [];

// let dataUsers = [];

function createData(
  id,
  name,
  department,
  organization,
  role,
  phone,
  email,
  mobile,
  status,
  tickets,
  ticketsCreated,
  tasks
) {
  return {
    id,
    name,
    department,
    organization,
    role,
    phone,
    email,
    mobile,
    status,
    tickets,
    ticketsCreated,
    tasks,
  };
}

function createDataCustomer(id, name) {
  return {
    label: name,
    id: id,
  };
}

const TicketsAddForm = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState("");
  const [messageCustomer, setMessageCustomer] = React.useState("");

  const [tSource, setTSource] = React.useState("Web");
  const [tDepartment, setTDepartment] = React.useState("Support");
  const [tAgent, setTAgent] = React.useState("0");
  const [tIssueType, setTIssueType] = React.useState("Hardware");
  const [tIssueTitle, setTIssueTitle] = React.useState("");
  const [tIssueDesc, setTIssueDesc] = React.useState("");
  const [tCustomer, setTCustomer] = React.useState("");
  const [tPriority, setTPriority] = React.useState("All");
  const [dataUsers, setDataUsers] = React.useState([]);
  const [usersRows, setUsersRows] = React.useState([]);
  const [customerUsers, setCustomerUsers] = React.useState([]);
  const [staffUsers, setStaffUsers] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [archives, setArchives] = React.useState([]);
  const [saltexSupportUser, setSaltexSupportUser] = React.useState("");
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let usersRowsT = [];
    let customerUsersT = [];
    let staffUsersT = [];
    const getUsers = async () => {
      const response = await axios.get(`${API_URL}users`);
      
      if (user.role == "Customer" && user.status == "Active") {
        let supportUser = response.data.users.filter((user) => {
          return (
            user.name === "Support" && user.organization === "Saltex Group"
          );
        });

        setSaltexSupportUser(supportUser[0].id);
        setTAgent(supportUser[0].id);
        setTCustomer(user._id);
      }

      let users = response.data.users.filter((user) => {
        return !(
          user.name === "Support" && user.organization === "Saltex Group"
        );
      });

      setDataUsers(response.data.users);
      users.forEach((user) => {
        usersRowsT.push(
          createData(
            user.id,
            user.name,
            user.department,
            user.organization,
            user.role,
            user.phone,
            user.email,
            user.mobile,
            user.status,
            user.tickets,
            user.ticketsCreated,
            user.tasks
          )
        );
      });
      setUsersRows(usersRowsT);

      usersRowsT.forEach((user) => {
        if (user.role == "Customer" && user.status == "Active") {
          customerUsersT.push(createDataCustomer(user.id, user.name));
        }
        setCustomerUsers(customerUsersT);

        if (
          (user.role == "Admin" || user.role == "Technician") &&
          user.status == "Active"
        ) {
          staffUsersT.push(createDataCustomer(user.id, user.name));
        }

        setStaffUsers(staffUsersT);
      });
    };
    getUsers();
  }, []);

  const navigate = useNavigate();

  const handleSubmitTicket = async (e) => {
    e.preventDefault();

    if (tCustomer === "") {
      setMessageCustomer(
        <Alert severity="error">{t("Please-Select-A-Customer")}</Alert>
      );
      setTimeout(function () {
        setMessageCustomer("");
      }, 5000);
    } else if (tAgent == "0" && user.role != "Customer") {
      setMessageCustomer(
        <Alert severity="error">{t("Please-Select-A-Technician")}</Alert>
      );
      setTimeout(function () {
        setMessageCustomer("");
      }, 5000);
    } else if (tPriority == "All") {
      setMessageCustomer(
        <Alert severity="error">{t("Please-Select-A-Priority")}</Alert>
      );
      setTimeout(function () {
        setMessageCustomer("");
      }, 5000);
    } else {
      let date = new Date();
      var current_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      let current_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        current_time;
      let duedate = new Date();
      let nextDate = new Date(duedate.setDate(duedate.getDate() + 5))
      

      duedate =
        nextDate.getFullYear() +
        "-" +
        (nextDate.getMonth() + 1) +
        "-" +
        (nextDate.getDate()) +
        " " +
        current_time;

      let formdata = new FormData();
      formdata.append("number", Math.floor(Math.random() * 900000) + 100000);
      formdata.append("status", "Open");
      formdata.append("department", tDepartment);
      formdata.append("creator", user._id);
      formdata.append("customerId", tCustomer);
      formdata.append("closed", "");
      files.forEach((file, index) => {
        formdata.append("image" + index, file);
      });
      formdata.append("updated", current_date);
      formdata.append("created", current_date);
      formdata.append("dueDate", duedate);
      formdata.append("pics", "");
      formdata.append("issue", tIssueType);
      formdata.append("title", tIssueTitle);
      formdata.append("description", tIssueDesc);
      formdata.append("staffId", tAgent);
      formdata.append("source", tSource);
      formdata.append("priority", tPriority);
      formdata.append("salesStatus", "None");

      // const ticketData = {
      //   number: Math.floor(Math.random() * 900000) + 100000,
      //   status: "Open",
      //   department: tDepartment,
      //   creator: user._id,
      //   customerId: tCustomer,
      //   closed: "",
      //   updated: current_date,
      //   created: current_date,
      //   dueDate: duedate,
      //   pics: "",
      //   issue: tIssueType,
      //   title: tIssueTitle,
      //   description: tIssueDesc,
      //   staffId: tAgent,
      //   source: tSource,
      //   priority: tPriority,
      // };
      // console.log(JSON.stringify({ ticketData }));

      try {
        const response = await fetch(API_URL + "tickets", {
          method: "POST",
          // headers: {
          //   "Content-Type": "Application/json",
          // },
          body: formdata,
        });


        const responseData = await response.json();

        setTCustomer("");
        setTPriority("Normal");
        setTSource("Web");
        setTDepartment("Support");
        setTAgent("0");
        setTIssueType("Hardware");
        setTIssueTitle("");
        setTIssueDesc("");
        localStorage.removeItem("pageFrom");
        localStorage.removeItem("taskId");
        localStorage.removeItem("ticketId");
        localStorage.setItem("pageFrom", "tickets");
        localStorage.setItem("ticketId", responseData.ticket._id);
        setMessageCustomer(
          <Alert severity="success">{t("Ticket Created")}</Alert>
        );
        setTimeout(function () {
          setMessageCustomer("");
          window.location.replace(
            "/ticketsDetails/?" + responseData.ticket._id
          );
        }, 2000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTCustomer = (userTCustomer) => {
    // const userTCustomer = event.target.value;
    // console.log(userTCustomer);
    setTCustomer(userTCustomer.id);
  };

  const handleTIssueTitle = (event) => {
    const userTIssueTitle = event.target.value;
    /// console.log(userNameText);
    setTIssueTitle(userTIssueTitle);
  };

  const handleTIssueDesc = (event) => {
    const userTIssueDesc = event.target.value;
    /// console.log(userNameText);
    setTIssueDesc(userTIssueDesc);
  };

  Array.prototype.remove = function () {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  };

  const handleTSourceChange = (event) => {
    setTSource(event.target.value);
  };

  const handleTDepartmentChange = (event) => {
    setTDepartment(event.target.value);
  };
  const handleTAgentChange = (event) => {
    setTAgent(event.target.value);
  };

  const handleTIssueTypeChange = (event) => {
    setTIssueType(event.target.value);
  };

  const handleTPriorityChange = (event) => {
    setTPriority(event.target.value);
  };

  const handleFiles = (data) => {
    setFiles(data);
  };

  const handleRemove = (data, file) =>{
    files.splice(files.indexOf(file), 1);
    const newFiles = [...files]   
    setFiles(newFiles)
    // setArchives([])
    setArchives(data)
  }

  return (
    <Box sx={{ width: "93%", m: 5 }}>
      {message} {messageCustomer}
      <Item>
        <form onSubmit={handleSubmitTicket}>
          <Typography className="text-lg font-bold leading-none my-3" paddingBottom={3}>
            {t("New-Tickets")}
          </Typography>
          <div>
            {user.role != "Customer" && (
              <div className="flex sm:space-x-24 mt-3 mb-6">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={customerUsers}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Customer")} />
                  )}
                  onChange={(e, value) => handleTCustomer(value)}
                  required
                />
              </div>
            )}
            {/* <Stack
              spacing={4}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              divider={<Divider orientation="vertical" flexItem />}
            > */}
            <Stack
              spacing={4}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <div className="flex sm:space-x-24 mb-6">
                <FormControl>
                  <FormHelperText>{t("Priority")}</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tPriority}
                    label={t("Priority")}
                    onChange={handleTPriorityChange}
                    sx={{ width: 200 }}
                  >
                    <MenuItem value="All">{t("All")}</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Urgent">{t("Urgent")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex sm:space-x-24 mb-6">
                <FormControl>
                  <FormHelperText>{t("Source")}</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tSource}
                    label={t("Source")}
                    onChange={handleTSourceChange}
                    fullWidth
                  >
                    <MenuItem value="Web">Web</MenuItem>
                    <MenuItem value="Phone">{t("Phone")}</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="Other">{t("Other")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex sm:space-x-24 mb-6">
                <FormControl>
                  <FormHelperText>{t("Department")}</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tDepartment}
                    label={t("Department")}
                    onChange={handleTDepartmentChange}
                    fullWidth
                  >
                    <MenuItem value="Support">{t("Support")}</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {user.role != "Customer" && (
                <div className="flex sm:space-x-24 mb-16">
                  <FormControl>
                    <FormHelperText>{t("Assigned-to")}: *</FormHelperText>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tAgent}
                      label={t("select-agent")}
                      onChange={handleTAgentChange}
                      required
                    >
                      <MenuItem value="0">{t("select-tech")}</MenuItem>
                      {staffUsers.map((tech) => (
                        <MenuItem value={tech.id}>{tech.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              {/* </Stack> */}
              <div className="flex sm:space-x-24 mb-6">
                <FormControl>
                  <FormHelperText>{t("Issue")}</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tIssueType}
                    label={t("Issue")}
                    onChange={handleTIssueTypeChange}
                    fullWidth
                  >
                    <MenuItem value="Hardware">Hardware</MenuItem>
                    <MenuItem value="Software">Software</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Stack>

            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="tIssueTitle"
                label={t("issue-title")}
                className="flex-auto"
                onChange={(e) => handleTIssueTitle(e)}
                value={tIssueTitle}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="tIssueDesc"
                label={t("issue-description")}
                multiline
                onChange={(e) => handleTIssueDesc(e)}
                value={tIssueDesc}
                rows={4}
                defaultValue=" "
                fullWidth
                required
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <MafiDragAndDrop onDropdown={handleFiles} onSetFiles={archives} onRemove={handleRemove}/>
            </div>

            <div className="flex sm:space-x-24 mb-16">
              <Button
                variant="outlined"
                color="error"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="button"
                onClick={hiddeExpPopUp}
                size="large"
                sx={{ display: props.displayCancel }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="outlined"
                color="success"
                className="mt-16"
                aria-label="Add"
                type="submit"
                fullWidth
              >
                {t("Add")}
              </Button>
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default TicketsAddForm;
