import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Box, Fab } from "@mui/material";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import { useTranslation } from "react-i18next";


const HOST = process.env.REACT_APP_FRONTEND_HOST

const BillingPrintPDF = () => {
  const { estimateNumber } = useParams();
  const [estimateData, setEstimateData] = useState(null);
  const componentRef = useRef();
  const { t } = useTranslation();

  

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}sales/${estimateNumber}`);
        setEstimateData(response.data.estimate);
      } catch (error) {
        console.error("Error fetching estimate data:", error);
      }
    };

    fetchEstimate();
  }, [estimateNumber]);

  const handlePrintTicket = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Estimate: ' + estimateNumber,
  });

  if (!estimateData) {
    return <p>Loading estimate data...</p>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <Fab title={t("Print")} sx={{marginBottom: "20px"}}>
                <LocalPrintshopRoundedIcon 
        onClick={handlePrintTicket}
        />
    </Fab>
    <Box ref={componentRef} sx={{ padding:'20px', marginTop:'20px'}}>
      <div dangerouslySetInnerHTML={{ __html: generateEstimateHTML(estimateData) }} />
    </Box>
    </div>
  );
};

const generateEstimateHTML = (estimateData) => {
  return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
  <!--[if gte mso 9]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="x-apple-disable-message-reformatting">
    <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
    <title></title>
    <style>
        body {
            font-family: Arial, sans-serif;
            font-size: 12px;
            margin: 0;
            background-color: #f5f5f5;
        }
        .container {
            width: 100%;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            margin: auto;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .company-info {
            flex: 1;
        }
        .company-info p {
            margin: 3px 0;
        }
        .company-logo {
            width: 250px;
            height: auto;
        }
        .estimate-details {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 20px;
        }
        .column {
            flex: 1;
            padding: 10px;
            border: 1px solid #ddd;
            background: #f9f9f9;
            border-radius: 5px;
        }
        .table-container {
            width: 100%;
            border-collapse: collapse;
        }
        .table-container th, .table-container td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }
        .table-container th {
            background-color: #f4f4f4;
        }
        .bottom-section {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-top: 20px;
        }
        .terms-column {
            flex: 2;
            padding: 10px;
            border: 1px solid #ddd;
            background: #f9f9f9;
            border-radius: 5px;
            font-size: 10px;
        }
        .total-column {
            flex: 1;
            padding: 10px;
            border: 1px solid #ddd;
            background: #f9f9f9;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
        }
        .total-table {
            width: 100%;
            border-collapse: collapse;
        }
        .total-table td {
            padding: 8px;
        }
        .total-table .left {
            text-align: left;
            font-weight: normal;
        }
        .total-table .right {
            text-align: right;
            font-weight: bold;
        }
        .acceptance {
            margin-top: 20px;
            text-align: left;
            font-size: 12px;
            font-weight: bold;
        }
    </style>
</head>
<body>
    <div class="container">
        <!-- Header with Company Info & Logo -->
        <div class="header">
            <div class="company-info">
                <h2><b>Saltex Group</b></h2>
                <p>7509 NW 36th St, Miami, FL 33166-6708</p>
                <p>+1 305 477 3187</p>
                <p>Email: sales@saltexgroup.com</p>
                <p>Website: <a href="https://www.saltexgroup.com">www.saltexgroup.com</a></p>
            </div>
            <div>
                <img src="${HOST}assets/logos/logo_company_dark_email.png" alt="Company Logo" class="company-logo">
            </div>
        </div>

        <!-- Estimate Details -->
        <div class="estimate-details">
            <div class="column">
                <h3>Bill To</h3>
                <p>${estimateData.billTo}</p>
                <p>${estimateData.email}</p>
            </div>
            <div class="column">
                <h3>Ship To</h3>
                <p>${estimateData.billTo}</p>
                <p>${estimateData.shipTo}</p>
            </div>
            <div class="column">
                <h3>Estimate Details</h3>
                <p><strong>Estimate No:</strong> ${estimateData.estimateNumber}</p>
                <p><strong>Date:</strong> ${new Date(estimateData.shippingDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</p>
                <p><strong>Payment Terms:</strong>  ${estimateData.paymentTerms}</p>
            </div>
        </div>

        <!-- Table of Items -->
        <table class="table-container">
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            ${estimateData.products.map(product => `
                <tr>
                  <td>${product.product}</td>
                  <td>${product.quantity}</td>
                  <td>$${product.rate}</td>
                  <td>$${product.amount}</td>
                </tr>`).join('')}
            </tbody>
        </table>

        <!-- Bottom Section (Terms & Total) -->
        <div class="bottom-section">
            <div class="terms-column">
                <p>Prices quoted are FOB Miami and valid for 30 days. Prices are exclusive of taxes, duties, and shipping unless specifically included on the quote.</p>
                <p>Payment Terms: Payment terms are as outlined in this invoice. Late payments will incur a 3% fee on the outstanding balance for every 30 days overdue.</p>
                <p>Warranty: Installation is warranted for 30 days from the completion date. Manufacturer warranties will apply to all equipment.</p>
                <p>Scope: This estimate covers only the items explicitly listed. Any additional work will incur extra charges.</p>
                <p>Acceptance: By accepting this estimate, the client agrees to these terms and conditions.</p>
            </div>

            <div class="total-column">
                <table class="total-table">
                    <tr>
                        <td class="left">Subtotal:</td>
                        <td class="right">$${estimateData.subtotal.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td class="left">Tax (6.5%):</td>
                        <td class="right">$${estimateData.salesTax.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td class="left"><strong>Total:</strong></td>
                        <td class="right"><strong>$${estimateData.total.toFixed(2)}</strong></td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- Acceptance Section -->
        <div class="acceptance"><br>
            <p>Accepted By: __________________________</p><br><br>
            <p>Accepted Date: ________________________</p>
        </div>
    </div>
</body>
</html>
`;
};

export default BillingPrintPDF;
