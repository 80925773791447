import { Suspense, useState, useCallback, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import PPMain from "../ppMain/PPMain";
import SaltexDashboard from "../../pages/SaltexDashboard";
import SaltexUsers from "../../pages/SaltexUsers";
import SaltexTickets from "../../pages/SaltexTickets";
import SaltexTask from "../../pages/SaltexTasks";
import SaltexTaskDetails from "../../pages/SaltexTasksDetails";
import SaltexTicketsDetails from "../../pages/SaltexTicketsDetails";
import SaltexOrganizations from "../../pages/SaltexOrganizations";
import SaltexClosedTask from "../../pages/SaltextClosedTask";
import SaltexNewUsers from "../../pages/SaltexNewUsers";
import SaltexNewUsersActivation from "../../pages/SaltexNewUsersActivation";
import SaltexNewPasswd from "../../pages/SaltexNewPasswd";
import SaltextEditOrganization from "../../pages/SaltexEditOrganization";
import SaltexEditUser from "../../pages/SaltexEditUser";
import SaltexProfile from "../../pages/SaltexProfile";
import SaltexReports from "../../pages/SaltexReports";
import SaltexReportsTasks from "../../pages/SaltexReportsTasks";
import ProtectedRoute from "../routes/ProtectedRoute";
import RecoveryPassword from "../ppMain/RecoveryPassword";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import SaltexBillingTicket from "../../pages/SaltexBillingTickets";
import SaltexSalesDashboard from "../../pages/SaltexSalesDashboard";
import SaltexBillingPDF from "../../pages/SaltexBillingPDF";

const API_URL = process.env.REACT_APP_API_URL;


const App = () => {
  const [openTickets, setOpenTickets] = useState(0);
  const [ticketsOpen, setTicketsOpen] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  function Loading() {
    return <h2>Loading...</h2>;
  }

  let daysNumber = 30;
  let today = new Date();
  let last30Days = new Date(new Date().setDate(today.getDate() - daysNumber));

  useEffect(() => {
    const getTickets = async () => {
      const response = await axios.get(
        `${API_URL}tickets/historical/${last30Days}/${today}`,
        {
          params: {
            role: user.role,
            id: user._id,
          },
        }
      );
      setTicketsOpen(response.data.open);
    };

    getTickets();
  }, []);

  useEffect(() => {
    let totalTickets = 0;

    ticketsOpen.forEach((register) => {
      totalTickets += register.count;
    });
    setOpenTickets(totalTickets);
  }, [ticketsOpen])

  const router = createBrowserRouter([
    { path: "/NewUser", element: <SaltexNewUsers /> },
    { path: "/NewUserActivation/*", element: <SaltexNewUsersActivation /> },
    { path: "/", element: <PPMain /> },
    { path: "/recovery-password", element: <RecoveryPassword /> },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute>
          <SaltexDashboard openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/tickets/",
      element: (
        <ProtectedRoute>
          <SaltexTickets openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/users",
      element: (
        <ProtectedRoute>
          <SaltexUsers openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/user/edit",
      element: (
        <ProtectedRoute>
          <SaltexEditUser openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/tasks",
      element: (
        <ProtectedRoute>
          <SaltexTask openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/closedTask/:id/:ticketId",
      element: (
        <ProtectedRoute>
          <SaltexClosedTask openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/tasksDetails/*",
      element: (
        <ProtectedRoute>
          <SaltexTaskDetails openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/billingPDF/:estimateNumber",
      element: (
        <ProtectedRoute>
          <SaltexBillingPDF openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/billingTicket/*",
      element: (
        <ProtectedRoute>
          <SaltexBillingTicket openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/salesDashboard/*",
      element: (
        <ProtectedRoute>
          <SaltexSalesDashboard openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ticketsDetails/*",
      element: (
        <ProtectedRoute>
          <SaltexTicketsDetails openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/reports",
      element: (
        <ProtectedRoute>
          <SaltexReports openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/reports-tasks",
      element: (
        <ProtectedRoute>
          <SaltexReportsTasks openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/Organizations",
      element: (
        <ProtectedRoute>
          <SaltexOrganizations openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/Organization/edit",
      element: (
        <ProtectedRoute>
          <SaltextEditOrganization openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ResetPassword",
      element: (
        <ProtectedRoute>
          <SaltexNewPasswd />
        </ProtectedRoute>
      ),
    },
    {
      path: "/Profile",
      element: (
        <ProtectedRoute>
          <SaltexProfile openTickets={openTickets} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile-update",
      element: (
        <ProtectedRoute>
          <Navigate to="/Profile" replace />
        </ProtectedRoute>
      ),
      // element: (
      //   <ProtectedRoute>
      //     <SaltexProfile />
      //   </ProtectedRoute>
      // ),
    },
  ]);

  const queryClient = new QueryClient();

  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
