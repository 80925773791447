import React, { useState } from "react";
import './MafiImageGallery.css';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import zIndex from "@mui/material/styles/zIndex";

const MafiImageGallery = ({galleryImages}) =>{
    
    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (index) => {
        setSlideNumber(index);
        setOpenModal(true);
    }

    const handleCloseModal = () =>{
        setOpenModal(false);
    }

    const prevSlide = () =>{
        slideNumber === 0 ? setSlideNumber( galleryImages.length - 1) : setSlideNumber(slideNumber-1);
    }

    const nextSlide = () =>{
        slideNumber + 1 === galleryImages.length ? setSlideNumber(0) : setSlideNumber(slideNumber+1);
    }

    return(
        <div>
            {openModal &&
                <div className="sliderWrap">
                    <CancelRoundedIcon className="btnClose" onClick={handleCloseModal}/>
                    <ChevronLeftRoundedIcon className="btnPrev" onClick={prevSlide}/>
                    <ChevronRightRoundedIcon className="btnNext" onClick={nextSlide}/>
                    <div className="fullScreenImage" >
                        <img src={galleryImages[slideNumber].img} alt="" style={{zIndex: 1600}} />
                    </div>
                </div>
            }
            <div className="galleryWrap">
                {
                    galleryImages && galleryImages.map((slide, index)=>{
                        return(
                            <div 
                                className="single" 
                                key={index}
                                onClick={() => handleOpenModal(index)}
                            >
                                <img src={slide.img} alt="" />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}





export default MafiImageGallery;