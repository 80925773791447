import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#4CAF50",
    height: "4px",
    borderRadius: "2px",
  },
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontSize: "16px",
  fontWeight: "600",
  "&.Mui-selected": {
    color: "#4CAF50",
  },
});

const API_URL = process.env.REACT_APP_API_URL;
const HOST = process.env.REACT_APP_HOST

const SalesDashboard = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [estimates, setEstimates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role;
  const name = user?.name;

  useEffect(() => {
    fetchEstimates("invoices");
  }, []);

  const fetchEstimates = async (statusFilter) => {
    try {
      const response = await axios.get(`${API_URL}sales/estimates/${statusFilter}/${role}/${name}`, {
        params: {
          role: role,
          customerName: name
        }
      });
      setEstimates(response.data.estimates);
    } catch (error) {
      console.error("Error fetching estimates:", error);
    }
  };

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
    const filters = ["invoices", "estimates", "notApproved"];
    fetchEstimates(filters[newIndex]);
  };

  const openBillingTicket = (id) => {
    navigate(`/billingTicket/?${id}`);
  };

  const totalAmount = estimates.reduce((sum, item) => sum + item.total, 0);

  const filteredEstimates = estimates.filter(estimate =>
    estimate.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>
        Sales Dashboard
      </Typography>

      <Card sx={{ boxShadow: 3, borderRadius: 3, overflow: "hidden" }}>
        <AppBar position="static" sx={{ backgroundColor: "white", boxShadow: 0 }}>
          <StyledTabs value={tabIndex} onChange={handleChange} centered>
            <StyledTab label="Invoices" />
            <StyledTab label="Estimates" />
            <StyledTab label="Not Approved Estimates" />
          </StyledTabs>
        </AppBar>
      </Card>

      <Box sx={{ marginTop: 3 }}>
        <Card sx={{ padding: 3, boxShadow: 3, borderRadius: 3 }}>
          <Typography variant="h6">Overview</Typography>
          <TextField
          label="Filter by Estimate Name"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2, marginTop: 2 }}
        />
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Estimate Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEstimates.map((estimate) => (
                  <TableRow key={estimate._id}>
                    <TableCell>{estimate.name}</TableCell>
                    <TableCell>{estimate.status}</TableCell>
                    <TableCell>${estimate.total.toFixed(2)}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => openBillingTicket(estimate.ticketId)}>
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="h6">Total:</Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography variant="h6">${totalAmount.toFixed(2)}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Box>
  );
};

export default SalesDashboard;
