import classes from "../../dashboard/Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import WorkIcon from "@mui/icons-material/Work";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

const OpenTicketsBox = () => {
  const { t } = useTranslation();
  const [OpenTickets, setOpenTickets] = useState([]);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));

  
  useEffect(() => {
    let ticketsSave = [];
    const getTickets = async () => {
      const response = await axios.get(
        `${API_URL}tickets/user/${userData._id}`,
        {
          params: {
            role: userData.role,
          },
        }
      );

      

      if (response.data.tickets) {
        response.data.tickets.forEach((ticket) => {
          const {
            _id: id,
            number,
            status,
            department,
            creator,
            // customerId: { _id: customerId } = {customerId: "" },
            // customerId: { name: customerName } = { customerName: "" },
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId: { _id: staffId },
            staffId: { name: staffName },
            source,
            priority,
          } = ticket;

          const customerId = ticket.customerId ? ticket.customerId._id : "";
          const customerName = ticket.customerId ? ticket.customerId.name : "";

          ticketsSave.push({
            id,
            number,
            status,
            department,
            creator,
            customerId,
            customerName,
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId,
            staffName,
            source,
            priority,
          });
        });
      }

      const tickesOpen = ticketsSave.filter((ticket) => {
        return ticket.status === "Open";
      });

      if(tickesOpen){
        setOpenTickets(tickesOpen.slice(0, 5));
      }
      
    };

    getTickets();
  }, []);

  const handleTickets = () => {
    let path = "/Tickets";
    navigate(path);
  };


  return (
    <Box>
      <Stack spacing={8} direction="row">
        <Typography class="text-slate-500 text-xl ">
          {t("open-tickets")}
        </Typography>
        <Button
          variant="text"
          class="text-slate-500 text-sm"
          className="saltexColor"
          onClick={handleTickets}
        >
          {t("view-more")}
        </Button>
      </Stack>
      <List sx={{ width: "100%", maxWidth: 400 }}>
        {OpenTickets.map((ticket) => {
          let createdTemp = "Created: " + ticket.created;
          return (
            <ListItem
              key={ticket.id}
              onClick={() => {
                navigate("/ticketsDetails/?" + ticket.id);
              }}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <KeyboardDoubleArrowRightIcon sx={{ color: "#0e4072" }} />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#0e4072", width: 35, height: 35 }}>
                  <WorkIcon sx={{ width: 20, height: 20 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={ticket.title}
                secondary={createdTemp}
                class="text-slate-500 text-sm"
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default OpenTicketsBox;
