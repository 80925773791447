import React from 'react';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material';
import { use } from 'react';
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete button
import MafiDragAndDrop from '../../@mafi/MafiDragAndDrop';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function createDataCustomer(id, name, email, address, phone, organization) {
  return {
    label: name,
    id: id,
    email: email,
    address: address,
    phone: phone,
    organization: organization,
  };
}

function createData(
  id,
  address,
  name,
  department,
  organization,
  role,
  phone,
  email,
  mobile,
  status,
  tickets,
  ticketsCreated,
  tasks
) {
  return {
    id,
    address,
    name,
    department,
    organization,
    role,
    phone,
    email,
    mobile,
    status,
    tickets,
    ticketsCreated,
    tasks,
  };
}

const API_URL = process.env.REACT_APP_API_URL;
const HOST = process.env.REACT_APP_HOST
let customerUsers = [];
let staffUsers = [];

let taxesValue = 7;

const BillingTickets = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tIssue, setTIssue] = React.useState("");
  const [tAgent, setTAgent] = React.useState("");
  const [tempIssue, setTempIssue] = React.useState("");
  const [tDesc, setTDesc] = React.useState();
  let location = useLocation();
  const [uniqueStaffUsers, setUniqueStaffUsers] = React.useState([]);
  const [ticketData, setTicketData] = React.useState([]);
  const [ticketStatus, setTicketStatus] = React.useState("");
  const [tAgentName, setTAgentName] = React.useState("");
  const [billTo, setBillTo] = React.useState("");
  const [shipTo, setShipTo] = React.useState("");
  const [shipVia, setShipVia] = React.useState("");
  const [shippingDate, setShippingDate] = React.useState("");
  const [acceptedDate, setAcceptedDate] = React.useState("");
  const [customerPO, setCustomerPO] = React.useState("");
  const [paymentTerms, setPaymentTerms] = React.useState("");
  const [estimateStatus, setEstimateStatus] = React.useState("Sent");
  const [memo, setMemo] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [archives, setArchives] = React.useState([]);
  const [acceptedDateDisabled, setAcceptedDateDisabled] = React.useState(false);
  const [oldEstimateId, setOldEstimateId] = React.useState("");

  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [estimateTotal, setEstimateTotal] = useState(0);
  const [currentEstimaStatus, setCurrentEstimaStatus] = useState("");

  const [salesTaxRate, setSalesTaxRate] = useState(taxesValue);

  const handleSalesTaxChange = (value) => {
    const taxRate = parseFloat(value) || 0; // Ensure it's a valid number
    setSalesTaxRate(taxRate);
    updateTotals(items, taxRate);
  };

  const productServices = {
    "LAB-MATERIALS-MISC": {
      name: "LAB-MATERIALS-MISC",
      description: "Miscellaneous materials",
    },
    "LAB-CONT-LABOR": {
      name: "LAB-CONT-LABOR",
      description: "Contract Labor",
    },
    "LAB-INST-CABLE": {
      name: "LAB-INST-CABLE",
      description: "Cable Installation - Installation and routing of low voltage cabling to support system infrastructure. Includes proper termination and testing to ensure reliable connectivity",
    },
    "LAB-INST-COMMIS": {
      name: "LAB-INST-COMMIS",
      description: "System Commisioning - Comprehensive testing and verification of system functionality to ensure all components operate as designed. Includes final adjustments and client handover for a fully operational solution"
      },
    "LAB-INST-CONDUIT": {
        name: "LAB-INST-CONDUIT",
        description: "Conduit Installation - Installation of conduit pathways for wiring to support system infrastructure"
        },
    "LAB-INST-CONFIG": {
        name: "LAB-INST-CONFIG",
        description: "Equipment Programming - Setup and configuration of system components, as per manufacturer standards for efficient and reliable operation. Includes device programming and system calibration"
        },
    "LAB-INST-CONSULTING": {
        name: "LAB-INST-CONSULTING",
        description: "System Consulting - Professional consulting services to evaluate system requirements, offer strategic recommendations, and support efficient project execution for security solutions."
        },
    "LAB-INST-ENG": {
        name: "LAB-INST-ENG",
        description: "System Engineering - Design and technical planning of systems, including system layout, device placement, and equipment specifications"
        },
    "LAB-INST-EQUIP": {
        name: "LAB-INST-EQUIP",
        description: "Equipment Installation - Installation of system equipment, including mounting and wiring connections to devices"
        },
    "LAB-INST-LABOR": {
        name: "LAB-INST-LABOR",
        description: "Installation Labor"
        },
    "LAB-INST-PROJ-MANAGER": {
        name: "LAB-INST-PROJ-MANAGER",
        description: "Project Manager - Serves as the primary liaison with the customer, coordinating with engineering and technical teams to plan, direct, and oversee all project phases, ensuring timely completion, effective communication, and adherence to budget and scope"
        },
    "LAB-INST-TEST": {
        name: "LAB-INST-TEST",
        description: "System Testing - Thorough testing and verification procedures to ensure proper functionality and performance of the system"
        },
    "LAB-INST-TRAINING": {
        name: "LAB-INST-TRAINING",
        description: "Training - Comprehensive training course on systems, designed to equip your team with the knowledge and skills needed for effective operation and maintenance"
        },  
    "LAB-INST-TRAVEL": {
        name: "LAB-INST-TRAVEL",
        description: "Travel Fees"
        },  
    "LAB-SERV-ONSITE-24-NB": {
        name: "LAB-SERV-ONSITE-24-NB",
        description: "24HR Response -  Non-Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-ONSITE-24-RB": {
        name: "LAB-SERV-ONSITE-24-RB",
        description: "24HR Response -  Regular Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-ONSITE-3-5-RB": { 
        name: "LAB-SERV-ONSITE-3-5-RB",
        description: "3-5 Day Response -  Regular Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-REMOTE-24-NB": {
        name: "LAB-SERV-REMOTE-24-NB",
        description: "24HR Response -  Non-Business Hours Remote Labor Fee"
        },
    "LAB-SERV-REMOTE-24-RB": {
        name: "LAB-SERV-REMOTE-24-RB",
        description: "24HR Response -  Regular Business Hours Remote Labor Fee"
        },
    "LAB-SERV-REMOTE-3-5-RB": {
        name: "LAB-SERV-REMOTE-3-5-RB",
        description: "3-5 Day Response -  Regular Business Hours Remote Labor Fee"
        },
    "LAB-SERV-SUPP-ONSITE-24-NB": {
        name: "LAB-SERV-SUPP-ONSITE-24-NB",
        description: "24HR Response -  Non-Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-SUPP-ONSITE-24-RB": {
        name: "LAB-SERV-SUPP-ONSITE-24-RB",
        description: "24HR Response -  Regular Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-SUPP-ONSITE-3-5-RB": {
        name: "LAB-SERV-SUPP-ONSITE-3-5-RB",
        description: "3-5 Day Response -  Regular Business Hours Onsite Labor Fee"
        },
    "LAB-SERV-SUPP-REMOTE-24-NB": {
        name: "LAB-SERV-SUPP-REMOTE-24-NB",
        description: "24HR Response -  Non-Business Hours Remote Labor Fee"
        },
    "LAB-SERV-SUPP-REMOTE-24-RB": {
        name: "LAB-SERV-SUPP-REMOTE-24-RB",
        description: "24HR Response -  Regular Business Hours Remote Labor Fee"
        },
    "LAB-SERV-SUPP-REMOTE-3-5-RB": {
        name: "LAB-SERV-SUPP-REMOTE-3-5-RB",
        description: "3-5 Day Response -  Regular Business Hours Remote Labor Fee"
        },
  }
  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toISOString().split("T")[0] : "";
  };

  const handleBillTo = (value) => {
    setBillTo(value);
  };

  const handleShipTo = (value) => {
    setShipTo(value);
  };

  const handleShipViaTo = (value) => { 
    setShipVia(value);  
  };

  const handleShippingDate = (value) => {
    setShippingDate(value);
  };

  const handleAcceptedDate = (value) => {
    setAcceptedDate(value); 
  };

  const handleCustomerPO = (value) => {
    setCustomerPO(value);
  };

  const handlePaymentTerms = (value) => {
    setPaymentTerms(value); 
  };

  const handleEstimateStatus = (value) => {
    setEstimateStatus(value);
  };

  const handleMemo = (value) => {
    setMemo(value);
  };

  const handleAddRow = () => {
    setItems([...items, { id: Date.now(), product: "", description: "", quantity: 1, rate: "", amount: 0 }]);
  };

  const handleFiles = (data) => {
    setFiles(data);
  };

  const handleRemove = (data, file) =>{
    files.splice(files.indexOf(file), 1);
    const newFiles = [...files]   
    setFiles(newFiles)
    // setArchives([])
    setArchives(data)
  }

  const handleChange = (index, field, value) => {
    const updatedItems = [...items];

    if (field === "product") {
      const selectedProduct = productServices[value];
      updatedItems[index] = {
        ...updatedItems[index],
        product: value,
        description: selectedProduct.description,
        quantity: 1, // Default quantity
        rate: "",
        amount: 0,
      };
    } else if (field === "quantity") {
      updatedItems[index].quantity = parseInt(value) || 1; // Ensure at least 1
    } else if (field === "rate") {
      updatedItems[index].rate = parseFloat(value) || 0;
    }

    updatedItems[index].amount = (updatedItems[index].rate || 0) * (updatedItems[index].quantity || 1);
    setItems(updatedItems);
    updateTotals(updatedItems);
  };

  const handleDeleteRow = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    updateTotals(updatedItems);
  };

  const updateTotals = (updatedItems, customTaxRate = salesTaxRate) => {
    const newSubtotal = updatedItems.reduce((sum, item) => sum + (item.amount || 0), 0);
    const newSalesTax = newSubtotal * (customTaxRate / 100);
    const newEstimateTotal = newSubtotal + newSalesTax - discount;
  
    setSubtotal(newSubtotal);
    setSalesTax(newSalesTax);
    setEstimateTotal(newEstimateTotal);
  };


  useEffect(() => {
    let ticketDataTemp = {};
    let dataUsers = [];
    let usersRows = [];
    let idTicket = location.search.replace("?", "");

    if (idTicket) {
      const getTicketDetails = async () => {
        const response = await axios.get(`${API_URL}tickets/${idTicket}`);
  
        if (response.data.ticket) {
          ticketDataTemp = response.data.ticket;
          ticketDataTemp.ticketAsignToId = response.data.ticket.staffId;
          setTIssue(response.data.ticket.title);
          setTempIssue(response.data.ticket.title);
          setTAgent(ticketDataTemp.ticketAsignToId);
          setTDesc(response.data.ticket.description);
      }
  
      let createDate = ticketDataTemp.created.split(".")[0].replace("T", " ");
      let dueDate = ticketDataTemp.dueDate.split(".")[0].replace("T", " ");
      let upDate = ticketDataTemp.updated.split(".")[0].replace("T", " ");

      if (ticketDataTemp.salesStatus != "None") {
        const salesData =  await axios.get(`${API_URL}sales/${ticketDataTemp.number}`);
        console.log(salesData.data);
        setBillTo(salesData.data.estimate.billTo);
        setShipTo(salesData.data.estimate.shipTo);
        setShipVia(salesData.data.estimate.shipVia);
        setShippingDate(salesData.data.estimate.shippingDate);
        setAcceptedDate(salesData.data.estimate.acceptanceDate);
        setCustomerPO(salesData.data.estimate.customerPO);
        setPaymentTerms(salesData.data.estimate.paymentTerms);
        setEstimateStatus(salesData.data.estimate.status);
        setMemo(salesData.data.estimate.memo);
        setSubtotal(salesData.data.estimate.subtotal);
        setSalesTax(salesData.data.estimate.salesTax);
        setDiscount(salesData.data.estimate.discount);
        setEstimateTotal(salesData.data.estimate.total);
        setItems(salesData.data.estimate.products);
        setOldEstimateId(salesData.data.estimate._id);
        setCurrentEstimaStatus(salesData.data.estimate.status);
      } else {
        setEstimateStatus("None");
        setAcceptedDateDisabled(true);
      }
      const responseUsers = await axios.get(`${API_URL}users`);

        dataUsers = responseUsers.data.users;
        dataUsers.forEach((user) => {
          usersRows.push(
            createData(
              user.id,
              user?.address,
              user.name,
              user.department,
              user.organization,
              user.role,
              user.phone,
              user.email,
              user.mobile,
              user.status,
              user.tickets,
              user.ticketsCreated,
              user.tasks
            )
          );
        });

        usersRows.forEach((user) => {
          if (user.role == "Customer" && user.status == "Active") {
            customerUsers.push(
              createDataCustomer(
                user.id,
                user.name,
                user.email,
                user.address,
                user.phone,
                user.organization
              )
            );
          }
          if (
            (user.role == "Admin" || user.role == "Technician") &&
            user.status == "Active"
          ) {
            staffUsers.push(createDataCustomer(user.id, user.name));
          }
        });

        setUniqueStaffUsers([
          ...new Map(staffUsers.map((m) => [m.id, m])).values(),
        ]);

        let technician = staffUsers.find(
          (staff) => staff.id == ticketDataTemp.staffId
        );
        if (technician == undefined){
          technician = {label:'Inactive'}
        }
        let customer = customerUsers.find(
          (customer) => customer.id == ticketDataTemp.customerId
        );

        setTicketData({
          ticketNum: ticketDataTemp.number,
          ticketId: ticketDataTemp.id,
          ticketTitle: ticketDataTemp.title,
          ticketDescription: ticketDataTemp.description,
          ticketStatus: ticketDataTemp.status,
          ticketPriority: ticketDataTemp.priority,
          ticketDepartment: ticketDataTemp.department,
          ticketCreateDay: createDate,
          ticketUser: customer.label,
          ticketUserEmail: customer.email,
          ticketUserAddress: customer.address,
          ticketUserPhone: customer.phone,
          ticketUserOrg: customer.organization,
          ticketSource: ticketDataTemp.source,
          ticketIssue: ticketDataTemp.issue,
          ticketAsignTo: technician.label,
          ticketAsignToId: ticketDataTemp.staffId,
          ticketDueDate: dueDate,
          ticketupDate: upDate,
        });
        setTAgentName(technician.label);
        setTicketStatus(ticketDataTemp.status);
        setBillTo(customer.label);
        setShipTo(customer.address);
      };

      

      getTicketDetails();
    }
  }, []);

const handleSubmit = async (e) => {
    e.preventDefault();

    let formdata = new FormData();

    // ✅ Attach images properly
    files.forEach((file, index) => {
      formdata.append("image" + index, file);
    });

    // ✅ Attach text fields
    formdata.append("name", ticketData.ticketUser);
    formdata.append("email", ticketData.ticketUserEmail);
    formdata.append("billTo", billTo);
    formdata.append("shipTo", shipTo);
    formdata.append("shipVia", shipVia);
    formdata.append("shippingDate", shippingDate);
    formdata.append("estimateNumber", ticketData.ticketNum);
    formdata.append("acceptanceDate", acceptedDate ? acceptedDate : "");
    formdata.append("salesRep", ticketData.ticketAsignTo);
    formdata.append("paymentTerms", paymentTerms);
    formdata.append("status", estimateStatus);
    formdata.append("customerPO", customerPO);
    formdata.append("memo", memo);
    formdata.append("subtotal", subtotal);
    formdata.append("salesTax", salesTax);
    formdata.append("discount", discount);
    formdata.append("total", estimateTotal);
    formdata.append("ticketId", ticketData.ticketId);

    // ✅ Convert `products` array to JSON before appending
    formdata.append("products", JSON.stringify(items.map(item => ({
      product: item.product,
      description: item.description,
      quantity: item.quantity,
      rate: item.rate,
      amount: item.amount,
    }))));
  
    try {
      // Submit new estimate
       const response = await axios.post(`${API_URL}sales/`, formdata, {
          headers: { "Content-Type": "application/json" }
      });
    
      // Update ticket status
      const responseSales = await axios.patch(`${API_URL}tickets/sales/${ticketData.ticketId}`, {
          "salesStatus": estimateStatus
      });


  
      if (response.status === 201 || response.status === 200) {
          alert("Estimate submitted successfully!");
          setItems([]); // Reset form after success
          setSubtotal(0);
          setSalesTax(0);
          // Attempt to delete the existing estimate
          try {
            const responseSalesDelete = await axios.delete(`${API_URL}sales/estimates/${oldEstimateId}`);
            console.log("Deleted existing estimate:", responseSalesDelete.data);
          } catch (error) {
              if (error.response) {
                  if (error.response.status === 404) {
                      console.warn("Estimate not found, proceeding with new submission.");
                  } else {
                      console.error("Error deleting estimate:", error.response.data);
                      throw new Error("Failed to delete existing estimate.");
                  }
              } else {
                  console.error("Network or unknown error while deleting:", error);
                  throw error;
              }
          }
          navigate('/salesDashboard');
      }
  } catch (error) {
      console.error("Error submitting estimate:", error);
      alert("Failed to submit estimate. Please try again.");
  }
  };
  


  return (
    <Box paddingTop={2}
    sx={{
      flex: { sx: 4, sm: 4, md: 4, lg: 5 },
      backgroundColor: "white",
      p: { sx: 1, sm: 1, md: 1, lg: 2 },
    }}>
      <Typography variant="h5" gutterBottom>
        ESTIMATE - {ticketData.ticketNum}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Saltex Group
      </Typography>
      <Typography variant="body2" gutterBottom>
        7509 NW 35th St, Miami, FL 33166
      </Typography>
      

      {/* Top Section */}
        <Grid container spacing={3} marginTop={2}>
          <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            label={t('Estimate Name')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            required
            fullWidth
            disabled
            value={ticketData.ticketUser}
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            label={t('Curtormer email')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            required
            fullWidth
            disabled
            value={ticketData.ticketUserEmail}
          />
          </Grid>
        </Grid>

        <Grid container spacing={3} marginTop={2}>
          <Grid item xs={12} sm={6}>
          <TextField
            id="billTo"
            label={t('Bill to')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            onChange={(e) => handleBillTo(e.target.value)}
            required
            fullWidth
            value={billTo}
            multiline rows={3}
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            id="shipTo"
            label={t('Ship to')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            onChange={(e) => handleShipTo(e.target.value)}
            required
            fullWidth
            value={shipTo}
            multiline rows={3}
          />
          </Grid>
        </Grid>

        <Grid container spacing={3} marginTop={2}>
          <Grid item xs={12} sm={3}>
            <TextField 
              id='shipVia'
              fullWidth 
              label= {shipVia ? "" : "Ship Via"} 
              variant="outlined" 
              onChange={(e) => handleShipViaTo(e.target.value)}  
              value={shipVia}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField 
              fullWidth 
              label="Shipping Date" 
              type="date" 
              variant="outlined"   
              onChange={(e) => handleShippingDate(e.target.value)}
              InputLabelProps={{ shrink: true }} 
              value={formatDate(shippingDate)}
              />
          </Grid>
          <Grid item xs={12} sm={3}>
          <TextField
            id="stimateNo"
            label={t('Estimate No.')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            required
            disabled
            value={ticketData.ticketNum}
          />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField 
              fullWidth 
              label="Accepted Date" 
              type="date" 
              variant="outlined" 
              disabled={acceptedDateDisabled}
              onChange={(e) => handleAcceptedDate(e.target.value)}
              InputLabelProps={{ shrink: true }} 
              value={formatDate(acceptedDate)}/>
          </Grid>
        </Grid>

        <Grid container spacing={3} marginTop={2}>
          <Grid item xs={12} sm={3}>
          <TextField
            id="salesRep"
            label={t('Sales Rep.')}
            className="flex-auto"
            InputLabelProps={{
            shrink: true,
            }}
            variant="outlined"
            required
            disabled
            value={ticketData.ticketAsignTo}
            fullWidth
          />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
          <InputLabel>Payment Terms</InputLabel>
          <Select
            onChange={(e) => handlePaymentTerms(e.target.value)}
            value={paymentTerms}
          >
            <MenuItem value={'Prepayment Upon Estimate Acceptance'}>Prepayment Upon Estimate Acceptance</MenuItem>
            <MenuItem value={'50% Prepayment - Balance Due Before Shipping'}>50% Prepayment - Balance Due Before Shipping</MenuItem>
            <MenuItem value={'50% Prepayment - 25% Upon Equipment Receipt - 25% Upon Completion'}>50% Prepayment - 25% Upon Equipment Receipt - 25% Upon Completion</MenuItem>
            <MenuItem value={'Net 30'}>Net 30</MenuItem>
            <MenuItem value={'Net 60'}>Net 60</MenuItem>
            <MenuItem value={'Cash on Delivery (COD)'}>Cash on Delivery (COD)</MenuItem>
          </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
          <InputLabel>Estimate Status</InputLabel>
          <Select
            onChange={(e) => handleEstimateStatus(e.target.value)}
            value={estimateStatus}
          >
            <MenuItem value={'Sent'}>Sent</MenuItem>
            <MenuItem value={'notApproved'}>Not Approved</MenuItem>
            <MenuItem value={'Approved'}>Approved</MenuItem>
          </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField 
              fullWidth 
              label= {customerPO ? "" : "Customer PO Number" }
              variant="outlined" 
              onChange={(e) => handleCustomerPO(e.target.value)}
              value={customerPO}  
            />
          </Grid>
        </Grid>

        {/* Product/Service Table */}
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product / Service</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{verticalAlign: "top"}}>  
                  <FormControl fullWidth>
                    <InputLabel>Product / Service</InputLabel>
                    <Select
                      value={item.product}
                      onChange={(e) => handleChange(index, "product", e.target.value)}
                      sx={{minWidth: 200}}
                    >
                      {Object.entries(productServices).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField variant="outlined" size="small" fullWidth value={item.description} multiline rows={4} sx={{minWidth: 280}}/>
                </TableCell>
                <TableCell sx={{verticalAlign: "top"}}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleChange(index, "quantity", e.target.value)}
                    sx={{maxWidth: 70}}
                  />
                </TableCell>
                <TableCell sx={{verticalAlign: "top"}}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={item.rate}
                    onChange={(e) => handleChange(index, "rate", parseFloat(e.target.value) || 0)}
                    sx={{maxWidth: 125}}
                  />
                </TableCell>
                <TableCell sx={{verticalAlign: "top"}}>
                  <TextField variant="outlined" size="small" fullWidth value={item.amount} disabled sx={{maxWidth: 125}} />
                </TableCell>
                <TableCell sx={{verticalAlign: "top", minWidth: 50}}>
                  <IconButton color="error" onClick={() => handleDeleteRow(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {currentEstimaStatus !== "Approved" && (
      <Box marginTop={2}>
        <Button variant="contained" onClick={handleAddRow}>
          Add Product / Service
        </Button>
      </Box>
      )}

      {/* Payment Section */}
      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <TextField 
            fullWidth 
            label="Memo on Statement"  
            variant="outlined" 
            multiline 
            onChange={(e) => handleMemo(e.target.value)}
            rows={3} 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <MafiDragAndDrop onDropdown={handleFiles} onSetFiles={archives} onRemove={handleRemove}/>

        </Grid>
      </Grid>

      {/* Totals Section */}
      <Grid container spacing={3} marginTop={4} justifyContent="flex-end">
  <Grid item xs={12} sm={4}>
    <Grid container spacing={1} alignItems="center">
      {/* Subtotal */}
      <Grid item xs={6}>
        <Typography variant="body1" align="left">Subtotal:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" align="right">${subtotal.toFixed(2)}</Typography>
      </Grid>

      {/* Sales Tax */}
      <Grid item xs={6}>
        <Typography variant="body1" align="left">Sales Tax (%):</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="number"
          label="(%)"
          variant="outlined"
          inputProps={{ step: "0.01" }} // Allow decimals
          value={salesTaxRate}
          onChange={(e) => handleSalesTaxChange(e.target.value)}
          sx={{ maxWidth: 80, textAlign: "right" }}
        />
      </Grid>

      {/* Sales Tax Amount */}
      <Grid item xs={6}>
        <Typography variant="body1" align="left">Sales Tax Amount:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" align="right">${salesTax.toFixed(2)}</Typography>
      </Grid>

      {/* Discount */}
      <Grid item xs={6}>
        <Typography variant="body1" align="left">Discount:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" align="right">$0.00</Typography>
      </Grid>

      {/* Estimate Total */}
      <Grid item xs={6}>
        <Typography variant="h6" align="left">Estimate Total:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" align="right">${estimateTotal.toFixed(2)}</Typography>
      </Grid>
    </Grid>
  </Grid>
</Grid>


      {/* Submit Button */}
      {currentEstimaStatus !== "Approved" && (
        <Box textAlign="center" marginTop={4}>
          <Button variant="contained" size="large" onClick={handleSubmit}>
            Submit Estimate
          </Button>
        </Box>
      )}


      {/* PDF Print Icon */}
      {currentEstimaStatus !== "Sent" && (
      <Box textAlign="center" sx={{ position: "absolute", top: "100px", right: "500px" }}>
        <IconButton onClick={() => window.open("/BillingPDF/" + ticketData.ticketNum, "_blank")}>
          <PictureAsPdfIcon color="error" fontSize="large" />
        </IconButton>
      </Box>
      )}

    </Box>
  );
};

export default BillingTickets;
