import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import Dashboard from "../main/dashboard/Dashboard";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import BillingTickets from "../main/billingTickets/BillingTickets";

const SaltexBillingTicket = ({ openTickets, onSetOpenTickets }) => {
  const [mode, setMode] = useState("light");
  const darkTheme = createTheme({
    palette: {
      mode: mode
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Box class="bg-slate-200">
        <NavBar totalOpenTickets={openTickets} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <PPNavigation />
          <BillingTickets onSetOpenTickets={onSetOpenTickets} />\
          <RightBar />
        </Stack>
        <AddTooltip />
      </Box>
    </ThemeProvider>
  );
}

export default SaltexBillingTicket;