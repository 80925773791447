import { Box} from "@mui/material";
import React from "react";
import TasksHeader from "./TasksHeader";
import TasksMain from "./widgets/TasksMain";
import TasksNew from "./widgets/TasksNew";

const Tasks = () =>{
    return(
        <Box paddingTop={2} sx={{flex:{sx:4, sm:4, md:4, lg:5}, backgroundColor:'white', p:{sx:1, sm:1, md:1, lg:2}}} >
            <TasksHeader />
            {/* <TasksNew /> */}
            <TasksMain /> 
        </Box>
    )
}

export default Tasks;