import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";
import React, { useEffect } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { AccountBox, Home, ModeNight, Settings, Article } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Task } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

const PPNavList = () => {
  const { t } = useTranslation();
  //   const [isAdminContent, setIsAdminContent] = React.useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user.role;

  //   useEffect(() => {
  //     if (userRole === "Admin") {
  //       setIsAdminContent(
  //         <>
  //           <List>
  //             <ListItem>
  //               <h6 className="text-center text-slate-500 text-xl">
  //                 {t("admin-panel")}
  //               </h6>
  //             </ListItem>
  //             <ListItem className="-mt-4">
  //               <h6 className="text-slate-500 text-xs">
  //                 {t("subtitle-admin-menu")}
  //               </h6>
  //             </ListItem>
  //             <ListItem disablePadding>
  //               <ListItemButton component="a" href="/Users">
  //                 <ListItemIcon>
  //                   <Home className="text-slate-500" />
  //                 </ListItemIcon>
  //                 <NavLink to="/Users" className="text-slate-500">
  //                   {t("users")}
  //                 </NavLink>
  //               </ListItemButton>
  //             </ListItem>

  //             {/* <ListItem disablePadding>
  //                         <ListItemButton component="a" href="#home">
  //                             <ListItemIcon>
  //                                 <Settings className="text-slate-500"/>
  //                             </ListItemIcon>
  //                             <ListItemText primary={t('settings')} className="text-slate-500" />
  //                         </ListItemButton>
  //                     </ListItem> */}

  //             <ListItem disablePadding className="mb-6">
  //               <ListItemButton component="a" href="/Organizations">
  //                 <ListItemIcon>
  //                   <CorporateFareIcon className="text-slate-500" />
  //                 </ListItemIcon>
  //                 <NavLink to="/Organizations" className="text-slate-500">
  //                   {t("Organizations")}
  //                 </NavLink>
  //               </ListItemButton>
  //             </ListItem>
  //           </List>
  //         </>
  //       );
  //     }
  //   }, []);

  return (
    <Box>
      <Divider />

      <List>
        <ListItem>
          <h6 className="text-center text-slate-500 text-xl">
            {t("dashboard")}
          </h6>
        </ListItem>
        <ListItem className="-mt-4">
          <h6 className="text-slate-500 text-xs">{t("subtitle-dash-menu")}</h6>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a">
            <ListItemIcon>
              <DashboardIcon className="text-slate-500" />
            </ListItemIcon>
            <NavLink to="/Dashboard" className="text-slate-500">
              {t("dashboard")}
            </NavLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="mb-6">
          <ListItemButton component="a">
            <ListItemIcon>
              <AssignmentIcon className="text-slate-500" />
            </ListItemIcon>
            <NavLink to="/Tickets" className="text-slate-500">
              Tickets
            </NavLink>
          </ListItemButton>
        </ListItem>
      </List>
      {userRole !== "Customer" && (
        <>
          <Divider />

          <List>
            <ListItem>
              <h6 className="text-center text-slate-500 text-xl">
                {t("technical")}
              </h6>
            </ListItem>
            <ListItem className="-mt-4">
              <h6 className="text-slate-500 text-xs">
                {t("subtitle-tech-menu")}
              </h6>
            </ListItem>
            <ListItem disablePadding className="mb-6">
              <ListItemButton component="a" to="/tasks">
                <ListItemIcon>
                  <PlaylistAddCheckIcon className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/Tasks" className="text-slate-500">
                  {t("Task")}s
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
        </>
      )}

      <Divider />
      {/* {isAdminContent} */}
      {userRole === "Admin" && (
        <>
          <List>
            <ListItem>
              <h6 className="text-center text-slate-500 text-xl">
                {t("report-panel")}
              </h6>
            </ListItem>
            <ListItem className="-mt-4">
              <h6 className="text-slate-500 text-xs">
                {t("subtitle-report-menu")}
              </h6>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" to="/Users">
                <ListItemIcon>
                  <Article className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/reports" className="text-slate-500">
                  {t("reports-tickets")}
                </NavLink>
              </ListItemButton>
            </ListItem>


            <ListItem disablePadding className="mb-6">
              <ListItemButton component="a" to="/Organizations">
                <ListItemIcon>
                  <Task className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/reports-tasks" className="text-slate-500">
                  {t("Task-Reports")}
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />

          <List>
            <ListItem>
              <h6 className="text-center text-slate-500 text-xl">
                {t("Billing Panel")}
              </h6>
            </ListItem>
            <ListItem className="-mt-4">
              <h6 className="text-slate-500 text-xs">
                {t("Billing Menu")}
              </h6>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" to="/Users">
                <ListItemIcon>
                  <Article className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/salesDashboard" className="text-slate-500">
                  {t("Sales Dashboard")}
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />

          <List>
            <ListItem>
              <h6 className="text-center text-slate-500 text-xl">
                {t("admin-panel")}
              </h6>
            </ListItem>
            <ListItem className="-mt-4">
              <h6 className="text-slate-500 text-xs">
                {t("subtitle-admin-menu")}
              </h6>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" to="/Users">
                <ListItemIcon>
                  <Home className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/Users" className="text-slate-500">
                  {t("User")}s
                </NavLink>
              </ListItemButton>
            </ListItem>


            <ListItem disablePadding className="mb-6">
              <ListItemButton component="a" to="/Organizations">
                <ListItemIcon>
                  <CorporateFareIcon className="text-slate-500" />
                </ListItemIcon>
                <NavLink to="/Organizations" className="text-slate-500">
                  {t("Organizations")}
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />


        </>
      )}


      <List>
        <ListItem>
          <h6 className="text-center text-slate-500 text-xl">{t("User")}</h6>
        </ListItem>
        <ListItem className="-mt-4">
          <h6 className="text-slate-500 text-xs">{t("subtitle-user-menu")}</h6>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" to="/Profile">
            <ListItemIcon>
              <AccountBox className="text-slate-500" />
            </ListItemIcon>
            <NavLink to="/Profile" className="text-slate-500">
              {t("Profile")}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component="a" to="/">
            <ListItemIcon>
              <Home className="text-slate-500" />
            </ListItemIcon>
            <NavLink to="/" className="text-slate-500">
              {t("Logout")}
            </NavLink>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default PPNavList;
