import { Chip, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import classes from "../Dashboard.module.css";

const DashOpenTasks = ({ totalOpenTasks, historical }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let categories = [];
  let data = [];
  const amount = 0;

  historical.forEach((register) => {
    categories.push(register._id);
    data.push(register.count);
  });
  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      width: "100%",
      type: "area",
      sparkline: {
        enabled: true,
      },
    },
    colors: [theme.palette.warning.main],
    fill: {
      colors: [theme.palette.warning.light],
      opacity: 0.5,
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      followCursor: true,
      theme: "dark",
    },
    xaxis: {
      type: "category",
      categories: categories,
    },
    grid: {
      show: true,
      borderColor: theme.palette.divider,
      padding: {
        top: 10,
        bottom: 15,
        left: 0,
        right: 0,
      },
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    series: [
      {
        name: "# Tasks",
        data: data,
      },
    ],
  };

  return (
    <Paper
      className={classes.totalTicketsChart}
      sx={{ width: { xs: "85%", sm: "85%", md: "45%", lg: "31%" } }}
    >
      <div>
        <Typography className={classes.totalTicketsTitle}>
          {t("open-tasks")}
        </Typography>
      </div>
      <div>
        <Typography className={classes.totalTicketsAmountDiv}>
          {/* {amount.toLocaleString('en-US')} */}
          {totalOpenTasks}
        </Typography>
      </div>
      <div>
        <ReactApexChart
          options={chartOptions}
          series={chartOptions.series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
    </Paper>
  );
};

export default DashOpenTasks;
