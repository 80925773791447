import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import TasksDetailHeader from "./TasksDetailsHeader";
import TaskStepper from "./widgets/TaskStepper";
import TasksDetailWidget from "./widgets/TasksDetailWidget";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL
let taskId = window.location.search.replace('?','');


const TaskDetails = () => {
  const[ticketNum, setTicketNum] = useState('');
  const[taskTitle, setTaskTitle] = useState('');
  const[taskObjetive, setTaskObjetive] = useState('');
  const [customerEmail, setCustomerEmail] = useState("")
  const [staffEmail, setStaffEmail] = useState("")
  let location = useLocation();
  
  useEffect(()=>{
    let idTask = location.search.replace('?','')
    const getTaskDataById = async () => {
      const response = await axios.get(`${API_URL}tasks/details/${idTask}`);
    
      if(response.data.Tasks.length > 0){
        setTicketNum(response.data.Tasks[0].ticketNum);
        setTaskTitle(response.data.Tasks[0].title);
        setTaskObjetive(response.data.Tasks[0].objetives);
      }
    };
    getTaskDataById();
  },[location.search]);
  
    return(
        <Box paddingTop={2} sx={{flex:{sx:4, sm:4, md:4, lg:5}, backgroundColor:'white', p:{sx:1, sm:1, md:1, lg:2}}} >
            <TasksDetailHeader />
            <TasksDetailWidget ticketNum={ticketNum} taskTitle={taskTitle} taskObjetive={taskObjetive}/>
            <Divider className="py-4"/>
            <TaskStepper /> 
        </Box>
    );
};

export default TaskDetails;