import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileMain from "./ProfileMain";
import ProfileHeader from "./ProfileHeader";


const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <ProfileHeader />
        <ProfileMain />
      
    </Box>
  );
};

export default Profile;
