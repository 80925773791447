import { Box, Typography } from "@mui/material";
import React from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  Timeline,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";

import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import { useTranslation } from "react-i18next";

const TicketThread = ({ replies }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h5" class="text-xl pt-4 text-blue-900 font-bold">
        {t("Ticket Threads")}
      </Typography>
      <Typography
        class="text-slate-500 text-sm"
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
      >
        {t("Replies and Internal Notes")}
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {replies.map((reply) => {
          return (
            <TimelineItem key={reply._id}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {`${new Date(reply.createdAt).toLocaleDateString(
                  "en-US"
                )} ${new Date(reply.createdAt).toLocaleTimeString()}`}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  color={reply.replyType === "Reply" ? "primary" : "secondary"}
                >
                  <CommentRoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {reply.replyType === "Reply"
                    ? reply.author
                    : `${reply.author} - Internal`}
                </Typography>
                <Typography>{reply.content}</Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
        {/* <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            05/07/2023 9:30 am
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot color="primary">
              <CommentRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              Marlon Santander
            </Typography>
            <Typography>
              Se arreglaron 2 camaras de las 4, se programa visita para mañana
              para terminar el trabajo
            </Typography>
          </TimelineContent>
        </TimelineItem> */}

        {/* <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            05/07/2023 9:30 am
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot color="secondary">
              <CommentRoundedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              Nick Pineda - Internal
            </Typography>
            <Typography>
              Las camaras nuevas estan en la bodega, pase a recogerlas antes de
              salir a donde el cliente
            </Typography>
          </TimelineContent>
        </TimelineItem> */}
      </Timeline>
    </Box>
  );
};

export default TicketThread;
