import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserNew from "./widgets/UserNew";
import UsersHeaderNew from "./UsersHeaderNew";

const API_URL = process.env.REACT_APP_API_URL
let dataUsers = [];
let usersRows = [];
let usersCountG = 0;

function createData(id, name, department, organization, role, phone, email, mobile, status, tickets, ticketsCreated, tasks) {
    return { id, name, department, organization, role, phone, email, mobile, status,  tickets, ticketsCreated, tasks};
  }


  const readUsersData = async() =>{
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'users')
        .then((response) => response.json())
        .then((jsonData) => {
            dataUsers = jsonData;
          resolve();
        });
    });
  };

readUsersData().then(function(){
    dataUsers.users.forEach(user => {
        usersRows.push(createData (user.id, user.name, user.department, user.organization, user.role, user.phone, user.email, user.mobile, user.status,  user.tickets, user.ticketsCreated, user.tasks));
        usersCountG++;
    });
});

const UsersNew= ()=>{
    const [userData, setUserData] = useState(usersRows);
    const [orgCount, setOrgCount] = useState(usersCountG);

    const addUsersListHandler = (user) => {
        setUserData(prevUser => {
              let contTemp = prevUser.length + 1;
              setOrgCount(contTemp);
              return [user, ...prevUser];
          });
      };


    return(
        <Box paddingTop={2} sx={{flex:{sx:4, sm:4, md:4, lg:5}, backgroundImage: "url('assets/bg_newUser.png')", borderRadius: '10px', p:{sx:1, sm:1, md:1, lg:2}}} >
        <UsersHeaderNew />
        <UserNew addUsersListHandler={addUsersListHandler} userData={userData}/>
        {/* <UserList filteredUsers={userData} usersCount={orgCount} /> */}
        </Box>
    );
}

export default UsersNew;