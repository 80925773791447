import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import UsersHeaderNewActivation from "./UsersHeaderNewActivation";

const UsersNewActivation= ()=>{


    return(
        <Box paddingTop={2} sx={{flex:{sx:4, sm:4, md:4, lg:5}, backgroundImage: "url('assets/bg_newUser.png')", borderRadius: '10px', p:{sx:1, sm:1, md:1, lg:2}}} >
        <UsersHeaderNewActivation />
        </Box>
    );
}

export default UsersNewActivation;