import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import OpenTaskBox from "./widgets/OpenTaskBox";
import { useTranslation } from 'react-i18next';
import OpenTicketsBox from "./widgets/OpenTicketsBox";

const RightBar = () =>{
    const { t } = useTranslation();
    return(
        <Box p={2} sx={{flex:{md:2, lg:1}, display:{ xs:"none", sm:"none", md:"block", lg:"block"}}}>
            <Box>
                <Stack direction='column' spacing={2}>
                    <Typography class="text-slate-500 text-xl">{t('to-do')}</Typography>
                    <Divider />
                    <OpenTicketsBox />
                    <Divider />
                    {/* <OpenTaskBox /> */}
                    {/* <Divider /> */}
                </Stack>
            </Box>
        </Box>
    )
}

export default RightBar;