
import classes from '../../dashboard/Dashboard.module.css';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

let allTask = [];
const user = JSON.parse(localStorage.getItem("user"));
const API_URL = process.env.REACT_APP_API_URL

const emptyRow = {
    Tasks: {
      closed: "",
      created: "",
      department: "",
      objetives: "",
      staffName: "",
      ticketNum: "",
      title: "",
      updated: "",
      _id: "",
    },
  };

const getTasksByStaffId = async () => {
  return new Promise(function (resolve, reject) {
    fetch(API_URL + "tasks/staff/" + user._id)
      .then((response) => response.json())
      .then((jsonData) => {
        allTask = jsonData;
        if (allTask.Tasks.length == 0) allTask = emptyRow;
        // rows = allTask;
        resolve();
      });
  });
};

const OpenTaskBox = () => {
    const { t } = useTranslation();
    return(
        <Box>
            <Stack spacing={8} direction="row">
                <Typography class="text-slate-500 text-xl ">
                    {t('open-tasks')}
                </Typography>
                <Button variant="text" class="text-slate-500 text-sm" className='saltexColor'>{t('view-more')}</Button> 
            </Stack>
            <List sx={{ width: '100%', maxWidth: 400 }}>
                <ListItem 
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                        <KeyboardDoubleArrowRightIcon sx={{ color: '#0e4072' }}/>
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#0e4072', width:35, height:35 }}>
                        <WorkIcon sx={{width:20, height:20}}/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Instalar Camara" secondary="Created Jan 9, 2023" class="text-slate-500 text-sm"/>
                </ListItem>
                <ListItem 
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                        <KeyboardDoubleArrowRightIcon sx={{ color: '#0e4072' }}/>
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#0e4072', width:35, height:35 }}>
                        <WorkIcon sx={{width:20, height:20}}/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Sensor Puerta" secondary="Created Jan 7, 2023" class="text-slate-500 text-sm"/>
                </ListItem>
                <ListItem 
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                        <KeyboardDoubleArrowRightIcon sx={{ color: '#0e4072' }}/>
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#0e4072', width:35, height:35 }}>
                        <WorkIcon sx={{width:20, height:20}}/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Servidor CCTV" secondary="Created Feb 20, 2023" class="text-slate-500 text-sm"/>
                </ListItem>
            </List>
            </Box>        
    )
}

export default OpenTaskBox;